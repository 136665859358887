<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Pages <span>Media</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="page-media-setting-form">
                <div class="modal_body pt-4">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Page Media?</h5>
                            <label for="show_media" class="switch_option capsule_btn border-0">
                                <input type="checkbox" id="show_media" :true-value="1" :false-value="0" v-model="form.display_media" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-show="form.display_media == 1">
                            <div class="mt-4 mb-4">
                                <div class="pb-4">
                                    <div class="capsule_elm">
                                        <h5>Video Link</h5>
                                        <label for="video_url" class="switch_option capsule_btn p-0">
                                            <input type="radio" id="video_url" name="instruction_type" :value="2" v-model="form.media.option" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="setting_wpr" v-if="form.media.option == 2">
                                        <div class="form_grp py-2">
                                            <div class="group_item">
                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.media_url }">
                                                    <Field autocomplete="off" type="text" name="media_url" placeholder="Video Link Goes Here..." v-model="form.media.url" rules="url" label="video url" />
                                                    <span class="prefix">URL</span>
                                                </div>
                                                <!-- <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small> -->
                                                <ErrorMessage name="media_url" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pb-4">
                                    <div class="capsule_elm">
                                        <h5>Embeded Video</h5>
                                        <label for="embed_video" class="switch_option capsule_btn p-0">
                                            <input type="radio" id="embed_video" name="instruction_type" :value="1" v-model="form.media.option" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="setting_wpr" v-if="form.media.option == 1">
                                        <div class="form_grp py-2">
                                            <div class="group_item">
                                                <div class="field_wpr" :class="{ 'has-error': errors.media_embed }">
                                                    <Field autocomplete="off" type="textarea" name="media_embed" v-model="form.media.embed">
                                                        <textarea cols="10" rows="10" placeholder="Embeded Code Goes Here..." v-model="form.media.embed"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="media_embed" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pb-4">
                                    <div class="capsule_elm">
                                        <h5>Image</h5>
                                        <label for="add_image" class="switch_option capsule_btn p-0">
                                            <input type="radio" id="add_image" name="instruction_type" :value="3" v-model="form.media.option" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="upload_image mt-3" v-if="form.media.option == 3">
                                        <image-library v-model="form.media.image" image-type="offer" upload-text="Image" />
                                    </div>
                                </div>
                            </div>
                            <h3 class="sub_header mt-2">Media Position</h3>
                            <div class="edit_section">
                                <div class="capsule_elm border-bottom">
                                    <h5>Above Content?</h5>
                                    <label for="above" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="above" value="above" v-model="form.media.display" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Below Content?</h5>
                                    <label for="below" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="below" value="below" v-model="form.media.display" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="pageUpdateLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                    <button :disabled="pageUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="pageUpdateLoader"></i> {{ pageUpdateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))
    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))

    export default {
        name: 'Page Media Setting',

        data () {
            return {
                form: {
                    display_media: 0,
                    media: {}
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageLibrary,
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            pageUpdateLoader: state => state.pageModule.pageUpdateLoader,
            selectedPage: state => state.pageModule.selectedPage,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePage: 'pageModule/updatePage',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    page_id: vm.selectedPage.id,
                    display_media: vm.selectedPage.display_media ? vm.selectedPage.display_media : 0,
                    media: vm.selectedPage.media ? JSON.parse(JSON.stringify(vm.selectedPage.media)) : { display: 'above', option: 3 },
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError = setFieldError;

                vm.updatePage(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>

<style scoped>
    :deep(.page-media-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.page-media-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }
</style>
